import React from 'react';
import styled, { keyframes } from 'styled-components';
import Flip from 'react-reveal/Flip';

const bounceAnimation = keyframes`
0 { transform:scale(1, 1);}
40% { transform:scale(0.95, 1);}
60% { transform:scale(1.05, 1); background-color: #2E2E7A; box-shadow: 0 9px 14px rgba(87, 76, 173, 0.27);}
100% { transform:scale(1, 1); background-color: #2E2E7A;box-shadow: 0 9px 14px rgba(87, 76, 173, 0.27);}
`;

const FormButtonInput = styled.input`
  height: 60px;
  padding: 0 20px;
  min-width: 150px;
  font-size: 15px;
  border:none;
  max-width: ${props => props.maxWidth};
  background-color: #df3d3e;
  text-transform: uppercase;
  border-radius: 30px;
  color: #fff;
  box-shadow: 0 9px 14px rgba(173, 76, 76, 0.27);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 200ms;
  :disabled {
    background-color: lightgray;
    box-shadow: 0 9px 14px rgba(176, 176, 176, 0.27);
    :hover {
      animation: none;
    }
  }
  :hover {
    animation: ${bounceAnimation} 0.4s linear forwards;
  }
  @media (max-width: 750px) {
    margin: 0 0 30px 0;
  }
`;

const FormButton = ({
  onClick, disabledButton, termsConfirmation, closedBtn,
}) => (
  <Flip left>
    {!closedBtn ? (
      <FormButtonInput
        disabled={disabledButton || !termsConfirmation}
        type="submit"
        id="submitButton"
        name="submitButton"
        onClick={onClick}
        value="send"
      />
    ) : (
      <FormButtonInput
        type="button"
        id="closeBtn"
        name="closeBtn"
        onClick={onClick}
        value="close"
      />
    )}
  </Flip>
);

export default FormButton;
