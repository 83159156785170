import styled from 'styled-components';

const GridWrapper = styled.div`
  width: 100%;
  max-width: 1238px;
  padding: 0 34px 30px 34px;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 730px) {
    padding: 0 5vw;
    margin-bottom: 0;
  }
`;
export default GridWrapper;
