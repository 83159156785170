import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const IconContainer = styled.div`
  display: inline-block;
  width: 40px;
  margin: 20px 20px 0 0;
  text-align: left;
  transition: all 0.3s;

  @media (max-width: 750px) {
    margin:10px;
  }
  :hover {
    transform: scale(0.9);
  }
`;

const SocialLink = styled.a``;

const SocialIcon = ({ img, alternativeText, linkTo }) => (
  <IconContainer>
    <SocialLink href={linkTo}>
      <img style={{ width: '100%' }} src={img} alt={alternativeText} />
    </SocialLink>
  </IconContainer>
);

export default SocialIcon;
