import React from 'react';
import androidIcon from '../../assets/images/icons/android.svg';
import desctopIcon from '../../assets/images/icons/desktop.svg';
import iOSIcon from '../../assets/images/icons/ios.svg';
import Icon from '../icon';
import HeadingH2 from '../textComponents/h2';
import GridWrapper from '../wrappers/grid-wrapper';
import SingleProject from './singleProject';
import BeasideLogo from '../../assets/images/projects-logo/beaside-logo.svg';

const ProjectsContainer = () => (
  <GridWrapper>
    <HeadingH2 marginTop="50px" marginBottom="50px">
      Our projects
    </HeadingH2>
    <SingleProject
      projectLogo={BeasideLogo}
      title="BeaSide"
      contentText="A simple key-tracker app. Contact us to get a free bluetooth beacon for you to use, or add your own to track your keys, wallet, bag, or cat… You name it!"
    >
      <Icon small img={desctopIcon} technology="Desktop" />
      <Icon small img={androidIcon} technology="android" />
      <Icon small img={iOSIcon} technology="IOS" />
    </SingleProject>
  </GridWrapper>
);

export default ProjectsContainer;
