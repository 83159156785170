import React from 'react';
import styled from 'styled-components';
import Flip from 'react-reveal/Flip';
import TreeImg from '../../assets/images/logo/sing.svg';
import BesideTextImg from '../../assets/images/logo/beside.svg';
import LabsImg from '../../assets/images/logo/LABS.svg';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 350px) {
    transform: scale(0.7);
  }
`;

const LogoSign = styled.div`
  background-image: url(${TreeImg});
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
`;
const BesideThePark = styled.div`
  background-image: url(${BesideTextImg});
  background-size: contain;
  background-repeat: no-repeat;
  width: 180px;
  height: 14px;
  margin-left: 10px;
`;
const Labs = styled.div`
  background-image: url(${LabsImg});
  background-size: contain;
  background-repeat: no-repeat;
  width: 60px;
  height: 35px;
  margin-left: 10px;
`;

const Logo = () => (
  <LogoContainer>
    <Flip left cascade>
      <LogoSign />
      <BesideThePark />
      <Labs />
    </Flip>
  </LogoContainer>
);
export default Logo;
