import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import HeadingH3 from '../textComponents/h3';
import HeadingH4 from '../textComponents/h4';
import MainParagraph from '../textComponents/mainParagraph';
import MainButton from '../inputs/MainButton';

const SingleProjectContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 120px 0;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    margin: 60px 0;
    border-top: 1px solid #d6d6d6;
  }
`;

const ProjectInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: space-between;
  & * {
    text-align: right;
  }

  @media (max-width: 750px) {
    width: 100%;
    order: 3;
    & * {
      text-align: center;
    }
  }
`;

const Devices = styled.div`
  display: flex;
  @media (max-width: 750px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
const DevicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 750px) {
    align-items: center;
    margin-bottom: 30px;
  }
`;

const Separator = styled.div`
  background-color: #171738;
  min-height: 500px;
  width: 3px;
  margin: 0 40px;

  @media (max-width: 750px) {
    display: none;
  }
`;

const ProjectImage = styled.div`
  width: 500px;
  min-height: 500px;
  border-radius: 50px;
  box-shadow: 0 11px 18px rgba(0, 0, 0, 0.14);
  padding: 50px;
  background-color: rgba(255, 255, 255, 0.18);
  display:flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 300px;
  }

  @media (max-width: 750px) {
    width: 100%;
    min-height: inherit;
    max-width: inherit;
    box-shadow: none;
    padding: 40px 0 20px 0;
    background-size: 20%;
    border-radius: -0;
    background-color: transparent;
  }
`;

const ImageContent = styled.img`
  max-height:400px;
  max-width:100%;
`;

const SingleProject = ({
  contentText, children, title, projectLogo,
}) => (
  <SingleProjectContainer>
    <ProjectInfo>
      <Fade left>
        <HeadingH3>{title}</HeadingH3>
        <MainParagraph>{contentText}</MainParagraph>
        <DevicesContainer>
          <HeadingH4 marginBottom="10px">Devices:</HeadingH4>
          <Devices>{children}</Devices>
        </DevicesContainer>
      </Fade>
      <MainButton marginTop="300px" path="https://beaside.btp-labs.com/" content="GO TO PROJECT" />
    </ProjectInfo>
    <Fade>
      <Separator />
    </Fade>
    <Flip right>
      <ProjectImage>
        <ImageContent src={projectLogo} alt={title} />
      </ProjectImage>
    </Flip>
  </SingleProjectContainer>
);

export default SingleProject;
