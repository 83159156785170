import React from 'react';
import Fade from 'react-reveal/Fade';
import GridWrapper from '../wrappers/grid-wrapper';
import HeadingH2 from '../textComponents/h2';
import FullBackgroundWrapper from '../wrappers/fullBackgroundWrapper';
import MainParagraph from '../textComponents/mainParagraph';
import NewsletterForm from './NewsletterForm';

const Newsletter = () => (
  <FullBackgroundWrapper bgColor="#fff">
    <GridWrapper>
      <Fade>
        <HeadingH2 marginTop="50px" marginBottom="50px">
          NEWSLETTER
        </HeadingH2>
      </Fade>
      <Fade right>
        <MainParagraph>
          Bacon ipsum dolor amet porchetta landjaeger shoulder, ball tip picanha beef ribs andouille
          ham ground round biltong capicola ribeye short loin venison.
        </MainParagraph>
      </Fade>
      <Fade left>
        <NewsletterForm />
      </Fade>
    </GridWrapper>
  </FullBackgroundWrapper>
);

export default Newsletter;
