import React from 'react';
import styled from 'styled-components';
import HeadingH4 from '../textComponents/h4';
import MainParagraph from '../textComponents/mainParagraph';
import CloseButton from '../inputs/FormButton';
import Icon from '../icon';
import okIcon from '../../assets/images/icon-check.svg';

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000cb;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  width: 80%;
  height: 80vh;
  max-width: 500px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PopupWindow = ({ onClick }) => (
  <PopupContainer>
    <PopupContent>
      <Icon img={okIcon} technology="ok" small />
      <HeadingH4>Thank you!</HeadingH4>
      <MainParagraph marginBottom="20px">Message has been sent</MainParagraph>
      <CloseButton closedBtn onClick={onClick} />
    </PopupContent>
  </PopupContainer>
);

export default PopupWindow;
