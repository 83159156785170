import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import '../../styles/fonts.css';
import './cookie-consent.css';

const OuterLink = styled.a`
display: inline-block;
margin:0 4px;
color:#fff;
:hover {
  text-decoration: none;
}
`;

const CookieConsentWrapper = () => (
  <CookieConsent
    location="bottom"
    buttonText="Got it!"
    cookieName="cookies"
    containerClasses="container-classes"
    contentClasses="content-classes"
    buttonClasses="button-classes"
    declineButtonClasses="button-classes"
    style={{ background: '#DF3D3E', fontWeight: 'bold', flexWrap: undefined }}
    contentStyle={{ flex: 'initial' }}
    buttonStyle={{
      color: '#DF3D3E',
      background: '#fff',
      fontWeight: 'bold',
      border: '1px solid #fff',
      padding: '10px 35px',
      borderRadius: '30px',
    }}
    expires={150}
    onAccept={() => {
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    }}
  >
    Beside the Park uses cookies to give you the best experience on our website. By continuing to
    browse the site, you are agreeing to our use of cookies. You can change your cookie settings at
    any time but if you do, you may lose some functionality. More information can be found in our
    <OuterLink href="https://www.besidethepark.com/privacy-policy">Privacy Policy</OuterLink>
    and
    <OuterLink href="https://www.besidethepark.com/cookies-policy">Cookies policy.</OuterLink>
  </CookieConsent>
);

export default CookieConsentWrapper;
