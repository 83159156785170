import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Logo from './logo';
import GridWrapper from '../wrappers/grid-wrapper';
import img from '../../assets/images/header-image.png';
import MainButton from '../inputs/MainButton';

const SiteHeader = styled.header`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 30px;
  justify-content: space-between;
  @media (max-width: 750px) {
    justify-content: center;
  }
`;

const HeaderWapper = styled.div`
  position: relative;
`;

const HeaderBackground = styled.div`
  background-image: url(${img});
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  background-position-x: 90%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: 142px;
  z-index: -1;
  @media (max-width: 1000px) {
    width: 100%;
    border-bottom-left-radius: 0;
    filter: opacity(0.5);
  }
`;

const HeaderContainer = styled.div`
  width: 50%;
  padding-right: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    justify-content: center;;
    padding:20px 20px 40px 20px;
  }
`;

const Heading1 = styled.h1`
  margin: 200px 0 50px 0;
  line-height: 1.2;
  @media (max-width: 1000px) {
    text-align: center;
    margin: 100px 0 30px 0;
  }
  @media (max-width: 750px) {
    justify-content: center;
    font-size: 50px;
    margin: 50px 0 30px 0;
  }
`;
const HeaderParagraph = styled.p`
  font-family: "main-regular";
  margin: 30px 30px 0 0;

  color: #919191;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 100px;

  @media (max-width: 1000px) {
   text-align:center;
   margin: 30px 0;
   font-size: 18px;
  }
`;

const Header = () => (
  <HeaderWapper>
    <GridWrapper>
      <SiteHeader>
        <HeaderContainer>
          <Link to="/">
            <Logo />
          </Link>
          <Fade cascade>
            <Heading1>The platform for&nbsp;giving back</Heading1>
            <HeaderParagraph>
              For years Beside The Park has been writing code and delivering projects for dozens of
              clients across Europe. Now&nbsp;we decided it’s time to give back to the world…
            </HeaderParagraph>
            <MainButton marginTop="300px" path="https://www.besidethepark.com/free-consult" content="ASK US WHAT CAN WE DO FOR YOU" />
          </Fade>
        </HeaderContainer>
      </SiteHeader>
    </GridWrapper>
    <HeaderBackground />
  </HeaderWapper>
);

export default Header;
