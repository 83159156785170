import React from 'react';
import styled, { keyframes, css } from 'styled-components';


const bounceAnimation = keyframes`
0 { transform:scale(1, 1);}
40% { transform:scale(0.95, 1);}
60% { transform:scale(1.05, 1);}
100% { transform:scale(1, 1); }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width:120px;
  height:120px;
  justify-content: center;
  align-items: center;
`;
const ImageGrey = styled.img`
  overflow: hidden;
  height:60px;
  width:60px;
  transition: all 0.2s;
  /* position:absolute; */
`;

const ImageWhite = styled.img`
  overflow: hidden;
  height: 0;
  /* position:absolute; */
  /* -webkit-filter: invert(.8); */
  filter: brightness(5);
  transition: all 0.5s;
`;

const animation = css`
animation: ${bounceAnimation} 0.4s linear forwards;
`;

const IconContainer = styled.div`
  width: 120px;
  height: 120px;
  transform:${props => (props.small ? 'scale(0.5)' : 'scale(1)')};
  border-radius: 100%;
  border: 1px solid #707070;
  margin: ${props => (props.small ? '-20px' : '20px')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
  overflow:hidden;

  @media (max-width: 750px) {
   transform: scale(0.8);
   margin: 0px;
  }

  & > img {
    /* transform: ${props => (props.small ? 'scale(0.6)' : 'scale(1)')}; */
  }
  &:hover {
    ${props => (!props.small ? animation : 'animation: none')};
    background-color: #2E2E7A;
    border: 1px solid #2E2E7A;
    ${ImageGrey}{
      height: 0;
    }
    ${ImageWhite}{
      height: 60px;
      width:60px;
    }
  }
`;

const Icon = ({ img, technology, small }) => (
  <IconContainer small={small}>
    <ImageContainer>
      <ImageGrey src={img} alt={technology} />
      <ImageWhite src={img} alt={technology} />
    </ImageContainer>
  </IconContainer>
);

export default Icon;
