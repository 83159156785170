import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import iconCheck from '../../assets/images/icon-check.svg';

const CheckBoxWrapper = styled.div`
  margin-bottom: 40px;
  position: relative;
`;

const Square = styled.div`
  order: -2;
  width: 22px;
  height: 22px;
  border: 1px solid #838383;
  border-radius: 5px;
  margin-right: 10px;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 12px;
  transform: translateY(-50%);
`;

const Label = styled.label`
  display: inline-block;
  font-size: 10px !important;
  max-width: 310px;
  padding-left: 30px !important;
  position: relative;
  z-index: 11;
  line-height: 16px;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input`
  display: none;
  &:checked {
    & + ${Square} {
      background: url(${iconCheck}) no-repeat center;
      background-size: 80%;
    }
  }
`;

const CheckBox = ({
  children, onChange, value, checked,
}) => (
  <Fade left>
    <CheckBoxWrapper>
      <Label htmlFor="termsConfirmation">{children}</Label>
      <HiddenCheckbox
        id="termsConfirmation"
        name="termsConfirmation"
        type="checkbox"
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <Square />
    </CheckBoxWrapper>
  </Fade>
);

export default CheckBox;
