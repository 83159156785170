import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import GridWrapper from '../wrappers/grid-wrapper';
import HeadingH2 from '../textComponents/h2';
import HeadingH4 from '../textComponents/h4';
import MainParagraph from '../textComponents/mainParagraph';
import Icon from '../icon';
import androidIcon from '../../assets/images/icons/android.svg';
import grapQlIcon from '../../assets/images/icons/GraphQL_Logo.svg';
import nodeIcon from '../../assets/images/icons/Node.js_logo.svg';
import iOSIcon from '../../assets/images/icons/ios.svg';
import reactNativeIcon from '../../assets/images/icons/react-native.svg';

const AboutContainer = styled.div``;

const IconsContainer = styled.div`
  margin: 30px 0 60px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const About = () => (
  <AboutContainer>
    <GridWrapper>
      <Fade bottom>
        <HeadingH2 marginTop="200px" marginBottom="50px">
          So, what is Beside The Park Labs?
        </HeadingH2>
      </Fade>
      <Fade left>
        <MainParagraph>
          Where there are a lot of skilled individuals in one place constrained by neatly organised
          scopes and feature lists within commercial projects, the creative juices are bound to
          spill over. We created the Beside The Park Labs as a platform for our team to publish
          their passion projects and bring their ideas to life. Below, you may download and use all
          of our tools and maybe you’ll think of something of your own that you would like to create
          with the help of Beside The Park
        </MainParagraph>
      </Fade>
      <Fade right>
        <HeadingH4 marginTop="60px">Our favourite technologies:</HeadingH4>
      </Fade>
      <Fade left>
        <IconsContainer>
          <Icon img={androidIcon} technology="android" />
          <Icon img={grapQlIcon} technology="android" />
          <Icon img={nodeIcon} technology="android" />
          <Icon img={iOSIcon} technology="android" />
          <Icon img={reactNativeIcon} technology="android" />
        </IconsContainer>
      </Fade>
    </GridWrapper>
  </AboutContainer>
);

export default About;
