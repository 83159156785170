import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import About from '../components/about';
import ProjectsContainer from '../components/projects';
import Newsletter from '../components/newsletter';
import Footer from '../components/footer';

const IndexPage = () => (
  <Layout>
    <SEO title="Beside the Park Labs" />
    <Header />
    <About />
    <ProjectsContainer />
    <Newsletter />
    <Footer />
  </Layout>
);

export default IndexPage;
