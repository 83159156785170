import React from 'react';
import styled, { keyframes } from 'styled-components';
import FormButton from './FormButton';

const FormContainer = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const bounceAnimation = keyframes`
0 { transform:scale(1, 1);}
40% { transform:scale(0.95, 1);}
60% { transform:scale(1.05, 1); background-color: #2E2E7A; box-shadow: 0 9px 14px rgba(87, 76, 173, 0.27);}
100% { transform:scale(1, 1); background-color: #2E2E7A;box-shadow: 0 9px 14px rgba(87, 76, 173, 0.27);}
`;

const InputFormContainer = styled.input`
  display: inline-block;
  width: 100%;
  max-width: 500px;
  height: 60px;
  border: 1px solid #838383;
  padding: 10px 20px;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 40px;
  outline: none;
  font-size: 18px;
  color: #000;
  padding-bottom: 10px;
  transition: all 0.3s;
  :focus::-webkit-input-placeholder {
    opacity: 0;
}

  ::placeholder {
    font-size: 16px;
    color: #959595;
  }
  :focus {
    border: 1px solid #2e2e7a;
    background-color: #df3d3e;
    color: #fff;
    animation: ${bounceAnimation} 0.4s linear forwards;
  }
`;

const Error = styled.div`
  font-size: 10px;
  color: red;
  transform: translateY(-30px);
  position: relative;
`;

const InputForm = ({ onChange, value, error }) => (
  <>
    <InputFormContainer
      type="text"
      name="inputForm"
      id="inputForm"
      placeholder="E-mail"
      value={value}
      onChange={onChange}
      error={error}
    />
    {!error && <Error>{error}</Error>}
  </>
);

export default InputForm;
