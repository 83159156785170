import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import Flip from 'react-reveal/Flip';

const bounceAnimation = keyframes`
0 { transform:scale(1, 1);}
40% { transform:scale(0.95, 1);}
60% { transform:scale(1.05, 1); background-color: #2E2E7A; box-shadow: 0 9px 14px rgba(87, 76, 173, 0.27);}
100% { transform:scale(1, 1); background-color: #2E2E7A;box-shadow: 0 9px 14px rgba(87, 76, 173, 0.27);}
`;

const ButtonWrapper = styled.div`
  padding: 15px 30px;
  background-color: #df3d3e;
  border-radius: 29px;
  box-shadow: 0 9px 14px rgba(173, 76, 76, 0.27);
  text-transform: uppercase;
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  transition: all 0.3s;
  text-align:center;
  line-height:1.2;
  display:inline-block;
  &:hover {
    animation: ${bounceAnimation} 0.4s linear forwards;
  }
`;

const MainButton = ({ path, content }) => (
  <Flip left>
    <a href={path} style={{ textDecoration: 'none' }}>
      <ButtonWrapper>{content}</ButtonWrapper>
    </a>
  </Flip>
);

export default MainButton;
