const Axios = require('axios');
const { apiBaseUrl } = require('./apiBaseUrl');

const axios = Axios.create({
  baseURL: apiBaseUrl,
  timeout: 0,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

module.exports = { axios };
