import React, { Component } from 'react';
import styled from 'styled-components';
import { axios } from '../../utils/axios';
import FormButton from '../inputs/FormButton';
import InputForm from '../inputs/InputForm';
import CheckBox from '../inputs/checkbox';
import PopupWindow from '../popupWindow';

const FormContainer = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

const Error = styled.p`
  display: inline-block;
  border-radius: 10px;
  border: 1px solid red;
  padding: 10px;
  margin: 0 0 30px 0;
  color: red;
  font-size: 12px;
`;

class NewsletterForm extends Component {
  state = {
    email: '',
    disabledButton: true,
    termsConfirmation: false,
    popupVisible: false,
    error: '',
  }

  handleClosePopup = () => {
    const { popupVisible } = this.state;
    this.setState({
      popupVisible: !popupVisible,
    });
  }

  handleInputChange = (event) => {
    const regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regx.test(event.target.value)) {
      this.setState({
        disabledButton: false,
      });
    } else {
      this.setState({
        disabledButton: true,
        error: false,
      });
    }
    this.setState({
      email: event.target.value,
      error: false,
    });
  }

  handleCheckBoxChange = () => {
    const { termsConfirmation } = this.state;
    this.setState({
      termsConfirmation: !termsConfirmation,
    });
  }

  handleFormSubmit = (event) => {
    const { email, termsConfirmation } = this.state;
    const data = {
      email,
      termsConfirmation,
    };
    event.preventDefault();
    axios
      .post('/newsletters.json', data)
      .then(() => {
        this.setState({
          email: '',
          termsConfirmation: false,
          popupVisible: true,
          disabledButton: true,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.email,
        });
      });
  }

  render() {
    const {
      disabledButton, termsConfirmation, email, popupVisible, error,
    } = this.state;
    return (
      <>
        {popupVisible && <PopupWindow onClick={this.handleClosePopup} />}
        <FormContainer>
          <InputForm onChange={this.handleInputChange} value={email} />
          {error && <Error>{`We are sorry, ${email} ${error}`}</Error>}
          {!disabledButton && (
            <CheckBox
              value="termsConfirmation"
              checked={termsConfirmation}
              onChange={this.handleCheckBoxChange}
            >
              I agree for my personal data included in the contact form to be processed by the
              Beside the Park. By submitting the application you confirm that you are familiar with
              our Information on processing of personal data. See more in the Beside the Park's
              Privacy-policy
            </CheckBox>
          )}
          <FormButton
            onClick={this.handleFormSubmit}
            termsConfirmation={termsConfirmation}
            disabledButton={disabledButton}
          />
        </FormContainer>
      </>
    );
  }
}

export default NewsletterForm;
