import styled from 'styled-components';

const FullBackgroundWrapper = styled.div`
  width: 100%;
  padding:50px 0; 
  margin: 0 auto;
  background-color: ${props => props.bgColor};

  @media (max-width: 750px) {
   padding:0;  }
`;
export default FullBackgroundWrapper;
