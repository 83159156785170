import React from 'react';
import styled from 'styled-components';
import GridWrapper from '../wrappers/grid-wrapper';
import FullBackgroundWrapper from '../wrappers/fullBackgroundWrapper';
import LogoBtPWhite from '../../assets/images/btp-logo-white.svg';
import SocialIcon from './socialIcon';
import FacebookLogo from '../../assets/images/social-icons/fb.svg';
import TweeterLogo from '../../assets/images/social-icons/twitter.svg';

const Separator = styled.div`
  background-color: #ffffff;
  width: 3px;
  margin: 0 40px;
  min-height: 150px;

  @media (max-width: 750px) {
    display: none;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;

const FooterLogoContainer = styled.a``;

const Address = styled.address`
  display: inline-block;
  color: #fff;
  & > p {
    margin: 5px 0;

    @media (max-width: 750px) {
      margin: 10px 0;
    }
  }
`;
const ExternalLink = styled.a`
  color: #fff;
  text-decoration: none;
  transition: all 0.3s;
  :hover {
    color: #df3d3e;
  }
`;

const SocialLinkContainer = styled.div`
  display: flex;

  @media (max-width: 750px) {
    justify-content: center;
    margin-bottom: 30px;
  }
`;

const PolicyLinksContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 30px auto 0;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  a {
    margin-right: 30px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    a {
      margin:10px 0;
    }

  }
`;

const CopyRight = styled.div`
  z-index: 2;
  color: #5f5f6c;
  font-size: 12px;
  text-align: center;
  background-color: #000;
  padding: 10px 0;
`;

const Footer = () => (
  <>
    <FullBackgroundWrapper styled={{ paddingBottom: '10px' }} bgColor="#171738">
      <GridWrapper>
        <FooterContainer style={{ padding: '50px 0 0 0' }}>
          <FooterLogoContainer href="https://www.besidethepark.com/">
            <img src={LogoBtPWhite} alt="Beside the park" />
          </FooterLogoContainer>
          <Separator />
          <Address>
            <p>Mazowiecka 9</p>
            <p>00-052 Warsaw, Poland</p>
            <p>+48 601 355 299</p>
            <p>
              <ExternalLink href="mailto:welcome@besidethepark.com">
                welcome@besidethepark.com
              </ExternalLink>
            </p>
            <SocialLinkContainer>
              <SocialIcon
                img={FacebookLogo}
                alternativeText="Facebook"
                linkTo="https://www.facebook.com/BesideThePark/"
              />
              <SocialIcon
                img={TweeterLogo}
                alternativeText="Tweeter"
                linkTo="https://twitter.com/besidethepark"
              />
            </SocialLinkContainer>
          </Address>
        </FooterContainer>
      </GridWrapper>
      <PolicyLinksContainer>
        <ExternalLink href="https://www.besidethepark.com/privacy-policy">
          Privacy policy
        </ExternalLink>
        <ExternalLink href="https://www.besidethepark.com/processing-of-personal-data">
          Processing of personal data
        </ExternalLink>
      </PolicyLinksContainer>
    </FullBackgroundWrapper>
    <CopyRight>BESIDE THE PARK © 2019. ALL RIGHTS RESERVED</CopyRight>
  </>
);

export default Footer;
